<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>
        <div class="header-search">
            <el-form ref="form" inline :model="searchData" label-width="">
                <el-form-item label="手机号">
                    <el-input v-model="searchData.mobile" size="small" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="变动时间">
                    <el-date-picker
                        style="width:300px;"
                        v-model="searchData.timeSlot"
                        size="small"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label=" ">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
                    <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-divider></el-divider>

        <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage"
                  :pageSize.sync="page_size"/>
    </div>
</template>

<script>
import {getPointsLogAPI} from './api'

const columns = [
    {
        label: 'ID',
        prop: 'id',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['id']}</p>
        }
    },
    {
        label: '会员信息',
        prop: 'user',
        minWidth: '120',
        customRender(h, row) {
            return (
                <div>
                    <div>昵称：{row['user']?.['nickname']}</div>
                    <div>手机号：{row['user']?.['mobile']}</div>
                </div>
            )
        }
    },
    {
        label: '类型',
        prop: 'name',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['name']}</p>
        }
    },
    {
        label: '积分',
        prop: 'integral',
        minWidth: '120',
        customRender(h, row) {
            return <p style="color: red;">+{row['integral']}</p>
        }
    },
    {
        label: '描述/说明',
        prop: 'desc',
        minWidth: '150',
        customRender(h, row) {
            return <p>{row['desc']}</p>
        }
    },
    {
        label: '变动时间',
        prop: 'created_at',
        minWidth: '150',
        customRender(h, row) {
            return <p>{row['created_at']}</p>
        }
    }
]
export default {
    name: 'List',

    data() {
        return {
            columns,
            currentPage: 1,
            page_size: 10,
            total: 0,
            list: [],
            searchData: {
                timeSlot: [],
                mobile: ''
            }
        }
    },
    provide() {
        return {
            context: this
        }
    },
    watch: {
        currentPage() {
            this.getList()
        },
        page_size() {
            this.getList()
        }
    },
    mounted() {
        this.getList()
    },

    methods: {
        async getList() {
            const {mobile, timeSlot} = this.searchData
            let params = {page: this.currentPage, pageSize: this.page_size, mobile}
            params.time_start = timeSlot[0] || ''
            params.time_end = timeSlot[1] || ''
            const res = await getPointsLogAPI(params)
            this.list = res.data || []
            this.total = res.total || 0
        },
        handleDelete({id}) {
            this.$confirm('此操作将永久删除该优惠券, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        },
        reset() {
            Object.assign(this._data.searchData, this.$options.data().searchData)
            this.getList()
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;

        .el-input {
          width: 250px;
        }

        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }

          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }

  .el-divider--horizontal {
    margin: 0 0 20px;
  }
}
</style>
